body{
    font-family:lato,sans-serif
}
.container{
    width: 100%;
    /* max-width:1000px; */
    /* margin-left:auto;
    margin-right:auto; */
    padding-left:10px;
    padding-right:10px
}
h2{
    font-size:26px;
    margin:20px 0;
    text-align:center
}
small{
    font-size:.5em
}
.table-header {
    border-radius:3px;
    padding:25px 30px;
    display:flex;
    justify-content:space-between;
    margin-bottom:25px
}
.table-row-accueil {
    border-radius:3px;
    padding:25px 30px;
    display:flex;
    justify-content:space-between;
    margin-bottom:25px
}
.table-header{
    background-color:#95A5A6;
    font-size:14px;
    text-transform:uppercase;
    letter-spacing:.03em
}
.table-row-accueil{
    background-color:#fff;
    box-shadow:0 0 20px 0 rgba(0,0,0,.1)
}
.col-1{
    flex-basis:10%
}
.col-2{
    flex-basis:40%
}
.col-3,.col-4{
    flex-basis:25%
}
/* @media all and (max-width:767px){
    .table-header{
        display:none
    }
    li{
        display:block
    }
    .col{
        flex-basis:100%;
        display:flex;
        padding:10px 0
    }
    .col:before{
        color:#6C7A89;
        padding-right:10px;
        content:attr(data-label);
        flex-basis:50%;
        text-align:right
    }
} */
#mysearch:focus + .input-group-addon {
	z-index: 10;
}

#search-btn:hover {
	cursor: pointer;
	/*color: gold;*/
	/* background-color: #ffc107; */
}
